<template>
  <div>
    <p v-if="!countsData && $apollo.queries.countsData.loading">
      Loading detection counts...
    </p>

    <p v-else-if="countsData">
      {{ formatCount(countsData.counts.species, 'species') }} |
      {{ formatCount(countsData.counts.detections, 'detection') }}
      <em>({{ periodDescription(period) }})</em>
    </p>

    <p>
      Last Detection:
      {{ station.latestDetectionAt | formatDate }}
      @
      {{ station.latestDetectionAt | formatTime }}

      <em>({{ timeSinceLastDetection.humanize() }} ago)</em>
    </p>
  </div>
</template>

<script>
import moment from 'moment'

import StationCountsQuery from 'queries/StationCounts.graphql'

import { periodDescription } from 'lib/period'
import { formatCount } from 'lib/formatting'

export default {
  props: {
    station: {
      type: Object,
      required: true
    },

    period: {
      type: Object,
      required: true
    },

    autoload: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      skipCounts: !this.autoload,
      countsData: null
    }
  },

  apollo: {
    countsData: {
      query: StationCountsQuery,

      variables () {
        return {
          stationId: this.station.id,
          period: this.period
        }
      },

      skip () {
        return this.skipCounts
      }
    }
  },

  methods: {
    periodDescription,
    formatCount,

    loadData () {
      this.skipCounts = false
      this.$apollo.queries.countsData.refetch()
    }
  },

  computed: {
    timeSinceLastDetection () {
      const latestDetectionAt = moment.parseZone(this.station.latestDetectionAt)
      const now = moment(new Date())
      return moment.duration(now.diff(latestDetectionAt))
    }
  }
}
</script>
