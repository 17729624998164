<template>
  <div class="d-flex align-items-center justify-content-center p-3">
    <div v-if="$apollo.queries.detections.loading" class="text-muted small">
      Loading soundscapes...
    </div>

    <Soundscape v-else-if="detections.nodes.length" class="flex-fill align-self-stretch" :soundscape="currentDetection.soundscape" :detection="currentDetection" :species="species" :shareLinks="true" @next="nextDetection" @previous="previousDetection" />

    <div v-else class="text-muted small">
      No soundscapes found.
    </div>
  </div>
</template>

<script>
import Soundscape from './Soundscape'

import DetectionsQuery from '../queries/Detections.graphql'

export default {
  props: {
    species: {
      type: Object,
      required: true
    },

    queryVariables: {
      type: Object
    }
  },

  data () {
    return {
      detections: [],
      detectionIndex: 0
    }
  },

  computed: {
    currentDetection () {
      return this.detections.nodes[this.detectionIndex]
    }
  },

  methods: {
    nextDetection () {
      this.detectionIndex = (this.detectionIndex + 1) % this.detections.nodes.length
    },

    previousDetection () {
      this.detectionIndex = (this.detectionIndex - 1) % this.detections.nodes.length
    }
  },

  apollo: {
    detections: {
      query: DetectionsQuery,

      variables () {
        return {
          ...this.queryVariables,
          speciesId: this.species.id,
          validSoundscape: true,
          limit: 20
        }
      }
    }
  },

  components: {
    Soundscape
  }
}
</script>
