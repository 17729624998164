<template>
  <l-layer-group ref="group" :pane="pane">
    <Heatmap :coords="coords" :zoom="zoom" />

    <template v-if="zoom > 9">
      <SightingMarker v-for="sighting in sightings.nodes" :key="sighting.id" :sighting="sighting" :species="species" :radius="markerRadius" />
    </template>
  </l-layer-group>
</template>

<script>
import Heatmap from 'components/Heatmap'
import SightingMarker from 'components/markers/SightingMarker'

import { LLayerGroup } from 'vue2-leaflet'

import BirdNETSightingsQuery from 'queries/BirdNETSightings.graphql'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    species: {
      type: Object,
      required: true
    },

    markerRadius: {
      type: Number,
      default: 5
    },

    queryVariables: {
      type: Object
    },

    pane: {
      type: String
    },

    zoom: {
      type: Number,
      default: 3
    }
  },

  data () {
    return {
      sightings: {},

      hasMore: true,
      offset: 0,
      perPage: 100
    }
  },

  computed: {
    coords () {
      if (this.sightings.nodes) {
        return this.sightings.nodes.map(s => s.coords)
      } else {
        return []
      }
    },

    allQueryVariables () {
      const variables = {
        ...this.queryVariables
      }

      if (this.species) {
        variables.speciesId = this.species.id
      }

      return variables
    }
  },

  methods: {
    updateModel (changes) {
      this.$emit('input', {
        ...this.value,
        ...changes
      })
    },

    loadMore () {
      this.$apollo.queries.sightings.fetchMore({
        variables: {
          ...this.allQueryVariables,
          offset: this.offset,
          limit: this.perPage
        },

        updateQuery (previous, { fetchMoreResult }) {
          return {
            sightings: {
              ...previous.sightings,

              nodes: [
                ...previous.sightings.nodes,
                ...fetchMoreResult.sightings.nodes
              ]
            }
          }
        }
      })
    }
  },

  apollo: {
    sightings: {
      query: BirdNETSightingsQuery,

      variables () {
        return {
          ...this.allQueryVariables,
          offset: 0,
          limit: this.perPage
        }
      },

      result ({ data }) {
        const resultCount = data.sightings.nodes.length

        if (resultCount > 0 && resultCount % this.perPage === 0) {
          this.offset += this.perPage
          this.perPage *= 2
          this.loadMore()
        } else {
          this.hasMore = false
        }

        return data
      },

      watchLoading (isLoading) {
        this.updateModel({ loading: isLoading })
      }
    }
  },

  watch: {
    species () {
      this.sightings = {}
    },

    sightings (sightings) {
      this.updateModel({ count: sightings.nodes ? sightings.nodes.length : 0 })
    },

    queryVariables () {
      this.perPage = 100
      this.offset = 0
      this.hasMore = true
    }
  },

  components: {
    Heatmap,
    LLayerGroup,
    SightingMarker
  }
}
</script>
