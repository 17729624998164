<template>
  <div class="station-stat">
    <font-awesome-icon v-if="loading" icon="spinner" size="xs" class="text-muted mb-1" spin />

    <strong v-else>
      {{ formattedValue }}
      <slot />
    </strong>

    <small>{{ caption }}</small>
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      required: true
    },

    value: {
      type: Number,
      required: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formattedValue () {
      return this.value ? this.value.toLocaleString() : 0
    }
  }
}
</script>
