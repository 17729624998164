<template>
  <button class="navbar-toggler app-toggler ms-2 d-block" :class="{ active: active }" type="button" aria-controls="topbar" aria-expanded="false" aria-label="Toggle layers panel" @click="$emit('toggle')">
    <font-awesome-icon :icon="icon" fixed-width />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "filter"
    },

    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
