import { DomUtil, Icon, Map } from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Map.include({
  _initControlPos: function () {
    const corners = this._controlCorners = {}
    const l = 'leaflet-'
    const container = this._controlContainer = DomUtil.create('div', l + 'control-container', this._container)

    function createCorner (vSide, hSide) {
      var className = l + vSide + ' ' + l + hSide

      corners[vSide + hSide] = DomUtil.create('div', className, container)
    }

    createCorner('top', 'left')
    createCorner('top', 'right')
    createCorner('bottom', 'left')
    createCorner('bottom', 'right')

    createCorner('top', 'center')
    createCorner('middle', 'center')
    createCorner('middle', 'left')
    createCorner('middle', 'right')
    createCorner('bottom', 'center')
  }
})
