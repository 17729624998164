import { isEmpty } from 'lodash'

function dateEquals (a, b) {
  return a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
}

function sameMonth (a, b) {
  return a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
}

function isToday (date) {
  const today = new Date()
  return dateEquals(date, today)
}

function isLastDayOfMonth (date) {
  return dateEquals(date, new Date(date.getFullYear(), date.getMonth() + 1, 0))
}

function isFullMonth (start, end) {
  return start.getDate() === 1 && sameMonth(start, end) && (isToday(end) || isLastDayOfMonth(end))
}

function formatMonth (date) {
  return `${date.toLocaleDateString(undefined, { month: 'long' })} ${date.getFullYear()}`
}

function formatDate (date) {
  return date.toLocaleDateString()
}

function formatDateRange (from, to) {
  return [formatDate(from), formatDate(to)].join(' - ')
}

export function periodDescription (period, capitalize = true) {
  if (isEmpty(period)) {
    return 'All time'
  }

  if (period.label) {
    return period.label
  } else if (period.from && period.to) {
    if (isFullMonth(period.from, period.to)) {
      return formatMonth(period.from)
    } else {
      return formatDateRange(period.from, period.to)
    }
  } else {
    const prefix = capitalize ? 'Last' : 'last'

    if (period.count === 1) {
      return `${prefix} ${period.unit}`
    } else {
      return `${prefix} ${period.count} ${period.unit}s`
    }
  }
}

export function shortPeriodDescription (period, capitalize = true) {
  if (period.label) {
    return period.label
  } else if (period.from && period.to) {
    return 'Date Range'
  } else {
    const prefix = capitalize ? 'Last' : 'last'
    return `${prefix} ${period.count}${period.unit[0]}`
  }
}

function daysIn(unit) {
  if (unit === 'hour') {
    return 1 / 24
  } else if (unit === 'day') {
    return 1
  } else if (unit === 'week') {
    return 7
  } else if (unit === 'month') {
    return 30
  } else if (unit === 'year') {
    return 365
  }
}

export function periodLengthInDays (period) {
  if (period.from && period.to) {
    const diff = period.to.getTime() - period.from.getTime()
    const days = diff / (1000 * 60 * 60 * 24)
    return days
  } else {
    const days = daysIn(period.unit)
    return period.count * days
  }
}
