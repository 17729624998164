<template>
  <div class="ratio" style="--bs-aspect-ratio: 50%; width: 95vw; max-width: 512px">
    <p v-if="loading" class="text-muted small d-flex align-items-center justify-content-center">Loading audio stream...</p>
    <p v-else-if="error" class="text-danger small d-flex align-items-center justify-content-center">An error occurred loading the stream.</p>

    <canvas v-show="loaded" ref="canvas"></canvas>

    <audio ref="audio" :src="source" autoplay crossorigin="anonymous" @loadeddata="loadAudio" @error="onError" />
  </div>
</template>

<script>
import Spectrogram from 'spectrogram'
import colormap from 'colormap'

export default {
  props: {
    source: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      loading: true,
      error: false
    }
  },

  mounted () {
    this.setupAudio()
    this.setupSpectrogram()
  },

  computed: {
    loaded () {
      return !this.loading && !this.error
    }
  },

  methods: {
    setupAudio () {
      this.audioContext = new AudioContext()

      this.audioSource = this.audioContext.createMediaElementSource(this.$refs.audio)
      this.audioAnalyser = this.audioContext.createAnalyser()

      this.audioAnalyser.smoothingTimeConstant = 0
      this.audioAnalyser.fftSize = 2048 * Math.round(window.devicePixelRatio)

      this.audioSource.connect(this.audioAnalyser)
      this.audioSource.connect(this.audioContext.destination)
    },

    setupSpectrogram () {
      this.spectrogram = new Spectrogram(this.$refs.canvas, {
        canvas: {
          width: this.$el.clientWidth * Math.round(window.devicePixelRatio),
          height: this.$el.clientHeight * Math.round(window.devicePixelRatio)
        },

        audio: {
          enable: true
        },

        colors: function(steps) {
          return colormap({
            colormap: 'jet',
            nshades: steps
          })
        }
      })

      window.audio = this.$refs.audio
    },

    loadAudio () {
      this.loading = false

      this.spectrogram.connectSource(this.audioAnalyser, this.audioContext)
      this.spectrogram.start()
    },

    onError (e) {
      this.loading = false
      this.error = true
    }
  },

  watch: {
    source () {
      this.loading = true
      this.error = false
    }
  }
}
</script>
