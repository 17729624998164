<template>
  <l-marker :lat-lng="arrayCoords" pane="species" @click="$emit('click', species)">
    <l-icon class-name="species-marker" :icon-anchor="iconAnchor">
      <transition :appear="true" enter-active-class="animate pop" leave-active-class="animate fade-out" @afterLeave="remove">
        <SpeciesThumbnail v-if="show" :species="species" class="rounded-circle" :style="thumbnailStyles" />
      </transition>
    </l-icon>

    <l-tooltip v-if="$slots.tooltip" :options="tooltipOptions">
      <slot name="tooltip" />
    </l-tooltip>
  </l-marker>
</template>

<script>
import { LIcon, LMarker, LTooltip } from 'vue2-leaflet'

import SpeciesThumbnail from '../SpeciesThumbnail'

export default {
  props: {
    species: {
      type: Object,
      required: true
    },

    coords: {
      type: Object,
      required: true
    },

    zoom: {
      type: Number,
      default: 3
    },

    delay: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      show: true
    }
  },

  methods: {
    fadeOut () {
      this.show = false
    },

    remove () {
      this.$emit('remove')

      this.$nextTick(() => {
        this.$destroy()
      })
    }
  },

  computed: {
    arrayCoords () {
      return [this.coords.lat, this.coords.lon]
    },

    iconAnchor () {
      const center = this.iconSize / 2
      return [center, center]
    },

    iconSize () {
      if (this.zoom > 12) {
        return 64
      } else if (this.zoom > 10) {
        return 48
      } else if (this.zoom > 8) {
        return 40
      } else if (this.zoom > 6) {
        return 32
      } else if (this.zoom > 4) {
        return 24
      } else {
        return 16
      }
    },

    borderWidth () {
      if (this.zoom > 10) {
        return 3
      } else {
        return 2
      }
    },

    thumbnailStyles () {
      return {
        '--thumbnail-size': `${this.iconSize}px`,
        '--thumbnail-border': `${this.borderWidth}px`,
        'animation-delay': `${this.delay}s`
      }
    },

    tooltipOptions () {
      return {
        direction: 'bottom',
        offset: [0, this.iconSize / 2]
      }
    }
  },

  components: {
    LIcon,
    LMarker,
    LTooltip,
    SpeciesThumbnail
  }
}
</script>
