<template>
  <MapLayer name="Mobile" pane="mobile">
    <v-marker-cluster :options="clusterOptions">
      <DetectionMarker v-for="detection in filteredDetections" :key="detection.id" :detection="detection" :radius="markerRadius" @click="$emit('click', $event)" />
    </v-marker-cluster>
  </MapLayer>
</template>

<script>
import DetectionMarker from 'components/markers/DetectionMarker'
import MapLayer from 'components/layers/MapLayer'

import MobileDetectionsQuery from 'queries/MobileDetections.graphql'

import VMarkerCluster from 'vue2-leaflet-markercluster'

import { LatLng } from 'leaflet'

export default {
  props: {
    queryVariables: {
      type: Object
    },

    station: {
      type: Object,
      required: true
    },

    species: {
      type: Object
    },

    zoom: {
      type: Number
    }
  },

  data () {
    return {
      detections: {},

      hasMore: true,
      offset: 0,
      perPage: 100
    }
  },

  methods: {
    loadMore () {
      this.$apollo.queries.detections.fetchMore({
        variables: {
          ...this.queryVariables,
          stationIds: [this.station.id],
          speciesId: this.species ? this.species.id : null,
          offset: this.offset,
          limit: this.perPage
        },

        updateQuery (previous, { fetchMoreResult }) {
          return {
            sightings: {
              ...previous.detections,

              nodes: [
                ...previous.detections.nodes,
                ...fetchMoreResult.detections.nodes
              ]
            }
          }
        }
      })
    },

    reset () {
      this.detections = {}
      this.perPage = 100
      this.offset = 0
      this.hasMore = true
    }
  },

  computed: {
    markerRadius () {
      return Math.min(this.zoom - 7, 10)
    },

    clusterOptions () {
      return {
        clusterPane: 'mobile',

        maxClusterRadius: 5,

        showCoverageOnHover: false,

        spiderLegPolylineOptions: {
          weight: 1,
          color: '#222',
          opacity: 0.25
        }
      }
    },

    filteredDetections () {
      if (!this.detections.nodes) {
        return []
      }

      if (this.station) {
        const stationLocation = new LatLng(this.station.coords.lat, this.station.coords.lon)
        return this.detections.nodes.filter((d) => {
          const detectionLocation = new LatLng(d.coords.lat, d.coords.lon)
          const distance = stationLocation.distanceTo(detectionLocation)
          return distance >= 50
        })
      } else {
        return this.detections.nodes
      }
    }
  },

  apollo: {
    detections: {
      query: MobileDetectionsQuery,

      variables () {
        return {
          ...this.queryVariables,
          stationIds: [this.station.id],
          speciesId: this.species ? this.species.id : null,
          offset: 0,
          limit: this.perPage
        }
      },

      result ({ data }) {
        const resultCount = data.detections.nodes.length

        if (resultCount > 0 && resultCount % this.perPage === 0) {
          this.offset += this.perPage
          this.perPage *= 2
          this.loadMore()
        } else {
          this.hasMore = false
        }

        return data
      }
    }
  },

  watch: {
    queryVariables () {
      this.reset()
    },

    station () {
      this.reset()
    },

    species () {
      this.reset()
    }
  },

  components: {
    DetectionMarker,
    MapLayer,
    VMarkerCluster
  }
}
</script>
