<template>
  <l-layer-group ref="group" :pane="pane">
    <Heatmap :coords="coords" :zoom="zoom" :gradient="{ 0.2: '#c179ed', 0.4: '#a646e2', 0.5: '#7e2ab2', 0.7: '#510083', 0.85: '#2b0047' }" />

    <template v-if="zoom > 9">
      <SightingMarker v-for="sighting in sightings" :key="sighting.id" :sighting="sighting" :species="species" :radius="markerRadius" label="eBird Sighting" />
    </template>
  </l-layer-group>
</template>

<script>
import Heatmap from 'components/Heatmap'
import SightingMarker from 'components/markers/SightingMarker'

import { LLayerGroup } from 'vue2-leaflet'

import EbirdSightingsQuery from 'queries/EbirdSightings.graphql'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    species: {
      type: Object,
      required: true
    },

    markerRadius: {
      type: Number,
      default: 5
    },

    queryVariables: {
      type: Object
    },

    pane: {
      type: String
    },

    zoom: {
      type: Number,
      default: 3
    }
  },

  data () {
    return {
      sightings: []
    }
  },

  computed: {
    coords () {
      return this.sightings.map((s) => s.coords)
    }
  },

  methods: {
    updateModel (changes) {
      this.$emit('input', {
        ...this.value,
        ...changes
      })
    }
  },

  apollo: {
    sightings: {
      query: EbirdSightingsQuery,

      variables () {
        return {
          ...this.queryVariables,
          speciesId: this.species.id
        }
      },

      watchLoading (isLoading) {
        this.updateModel({ loading: isLoading })
      }
    }
  },

  watch: {
    species () {
      this.sightings = []
    },

    sightings (sightings) {
      this.updateModel({ count: sightings.length })
    }
  },

  components: {
    Heatmap,
    LLayerGroup,
    SightingMarker
  }
}
</script>
