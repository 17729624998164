<template>
  <div class="station-info d-flex align-items-center">
    <div class="flex-fill">
      <header class="station-info-header">
        <p>
          <em class="text-muted">#</em>{{ station.id }} <span class="text-muted">-</span>
          <strong>{{ station.name }}</strong>

          <small v-if="fixedStation" class="text-muted">- {{ stationType }}</small>
        </p>
      </header>

      <div class="station-info-main">
        <StationWeatherInfo ref="weather" :station="station" :autoload="autoload" />

        <hr />

        <StationDetectionInfo ref="detections" :station="station" :period="period" :autoload="autoload" />

        <template v-if="puc">
          <hr />

          <StationSensorInfo ref="sensors" :station="station" :autoload="autoload" />
        </template>

        <template v-if="!tooltip && station.hasProbabilities">
          <hr />

          <a href="#" @click.prevent="showProbabilities">Species Probabilities</a>

          <SpeciesProbabilities v-if="showProbabilitiesModal" ref="probabilities" :station="station" />
        </template>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import StationDetectionInfo from './StationDetectionInfo'
import StationSensorInfo from './StationSensorInfo'
import StationWeatherInfo from './StationWeatherInfo'
import SpeciesProbabilities from './SpeciesProbabilities'

import { stationType } from '../lib/station'

export default {
  props: {
    station: {
      type: Object,
      required: true
    },

    period: {
      type: Object,
      required: true
    },

    tooltip: {
      type: Boolean,
      default: false
    },

    autoload: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showProbabilitiesModal: false
    }
  },

  computed: {
    stationType () {
      return stationType(this.station.type)
    },

    fixedStation () {
      return this.station.type !== 'app'
    },

    puc () {
      return this.station.type === 'puc'
    }
  },

  methods: {
    loadData () {
      this.$refs.weather.loadData()
      this.$refs.detections.loadData()

      if (this.puc) {
        this.$refs.sensors.loadData()
      }
    },

    showProbabilities () {
      this.showProbabilitiesModal = true
      this.$nextTick(() => this.$refs.probabilities.show())
    }
  },

  components: {
    StationDetectionInfo,
    StationSensorInfo,
    StationWeatherInfo,
    SpeciesProbabilities
  }
}
</script>
