<template>
  <div class="detection-notification toast fade clickable" role="alert" aria-live="assertive" aria-atomic="true" @click="$emit('click', detection)">
    <div class="toast-body">
      <SpeciesThumbnail class="d-block medium rounded-circle" :species="detection.species" />

      <div class="detection-notification-main">
        <div class="detection-notification-info flex-grow-1 small">
          <p class="mb-0">
            {{ translatedCommonName }}
            <em class="text-muted">({{ detection.species.scientificName }})</em>
          </p>

          <p v-if="detection.station" class="small text-muted mb-0">
            @ {{ detection.station.name }}
          </p>
        </div>

        <DetectionScore class="flex-shrink-0" :detection="detection" tooltipPlacement="bottom" />
      </div>
    </div>
  </div>
</template>

<script>
import DetectionScore from './DetectionScore'
import SpeciesThumbnail from './SpeciesThumbnail'

import Toast from 'bootstrap/js/dist/toast'

import { translatedCommonName } from 'lib/species'

import { mapState } from 'vuex'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.toast = new Toast(this.$el)
    this.toast.show()

    this.$el.addEventListener('hidden.bs.toast', () => this.$emit('remove', this.detection))
  },

  destroyed () {
    this.toast.dispose()
  },

  computed: {
    ...mapState(['locale']),

    translatedCommonName () {
      return translatedCommonName(this.detection.species, this.locale)
    }
  },

  components: {
    DetectionScore,
    SpeciesThumbnail
  }
}
</script>
