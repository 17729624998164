<template>
  <Modal title="Soundscape" ref="modal" sizeClass="modal-lg" @show="$emit('show')" @hide="$emit('hide')">
    <Soundscape :soundscape="soundscape" :shareLinks="false" :showNavigator="showNavigator" @next="$emit('next')" @previous="$emit('previous')" />

    <table v-if="soundscape.detections.length" class="table mt-4 mb-0">
      <tr v-for="detection in soundscape.detections" :key="detection.id">
        <td><SpeciesThumbnail class="d-block small rounded" :species="detection.species" /></td>
        <td>{{ detection.species.commonName }}</td>
        <td><em class="text-muted fw-light">({{ detection.species.scientificName }})</em></td>
        <td class="text-end"><DetectionScore class="d-inline-block" :detection="detection" /></td>
      </tr>
    </table>

    <template v-slot:footer>
      <div class="flex-fill d-flex justify-content-between align-items-center">
        <div>
          <button v-if="false" type="button" class="btn p-1" @click="shareViaFacebook" style="color: #4267b2">
            <font-awesome-icon icon="fab fa-facebook-square" />
          </button>
        </div>

        <button type="button" class="btn btn-secondary px-4" @click="hide">Close</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import DetectionScore from './DetectionScore'
import Modal from './Modal'
import Soundscape from './Soundscape'
import SpeciesThumbnail from './SpeciesThumbnail'

export default {
  props: {
    soundscape: {
      type: Object,
      required: true
    },

    showNavigator: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    show () { this.$refs.modal.show() },
    hide () { this.$refs.modal.hide() },

    shareViaFacebook () {
      FB.ui({
        method: 'share',
        href: this.soundscapeUrl,
      }, () => {})
    }
  },

  computed: {
    soundscapeUrl () {
      return `${document.location.origin}/soundscapes/${this.soundscape.id}`
    }
  },

  components: {
    DetectionScore,
    Modal,
    Soundscape,
    SpeciesThumbnail
  }
}
</script>
