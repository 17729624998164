<template>
  <div class="navbar-collapse" :class="{ collapse: !collapsing, show: (active && !collapsing), collapsing: collapsing }" :style="{ height: height }" @transitionend="finished">
    <slot />
  </div>
</template>

<script>
const reflow = (element) => element.offsetHeight

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      collapsing: false,
      height: null
    }
  },

  methods: {
    toggle () {
      if (this.active) {
        this.hide()
      } else {
        this.show()
      }
    },

    show () {
      this.$emit('update:active', true)
    },

    hide () {
      this.$emit('update:active', false)
    },

    finished () {
      this.collapsing = false
      this.height = null
    }
  },

  watch: {
    active (active) {
      if (active) {
        this.height = 0
        this.collapsing = true

        this.$nextTick(() => {
          this.height = `${this.$el.scrollHeight}px`
        })
      } else {
        this.height = `${this.$el.getBoundingClientRect().height}px`

        this.collapsing = true

        this.$nextTick(() => {
          reflow(this.$el)
          this.height = ''
        })
      }
    }
  }
}
</script>
