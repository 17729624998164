<template>
  <l-layer-group ref="group" :name="name" :pane="pane" :visible="visible" layer-type="overlay">
    <slot />
  </l-layer-group>
</template>

<script>
import { LLayerGroup } from 'vue2-leaflet'

export default {
  props: {
    name: {
      type: String,
    },

    pane: {
      type: String
    },

    visible: {
      type: Boolean,
      default: true
    }
  },

  components: {
    LLayerGroup
  }
}
</script>
