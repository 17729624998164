<template>
  <MapWindow class="species-info-window" :zIndex="zIndex" @hide="$emit('hide')" @bringToFront="$emit('bringToFront')">
    <template v-slot:header>
      <SpeciesHeader :species="species" />
    </template>

    <template v-slot:close>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </template>

    <SpeciesView :species="species" :queryVariables="queryVariables" :period="period" />
  </MapWindow>
</template>

<script>
import MapWindow from '../components/MapWindow'
import SpeciesHeader from '../components/SpeciesHeader'
import SpeciesView from '../components/SpeciesView'

export default {
  props: {
    species: {
      type: Object
    },

    queryVariables: {
      type: Object
    },

    period: {
      type: Object
    },

    zIndex: {
      type: Number,
      default: 1
    }
  },

  components: {
    MapWindow,
    SpeciesHeader,
    SpeciesView
  }
}
</script>
