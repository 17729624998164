<template>
  <div>
    <button class="vote-button" :class="{ active: this.recordedVote === -1 }" @click="vote(-1)"><font-awesome-icon icon="thumbs-down" /></button>
    <button class="vote-button" :class="{ active: this.recordedVote === 1 }" @click="vote(1)"><font-awesome-icon icon="thumbs-up" /></button>
  </div>
</template>

<script>
import Tooltip from 'bootstrap/js/dist/tooltip'

import { FetchRequest } from '@rails/request.js'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      recordedVote: null
    }
  },

  created () {
    this.loadVote()
  },

  mounted () {
    this.createTooltip()
  },

  destroyed () {
    this.destroyTooltip()
  },

  methods: {
    async vote (score) {
      this.recordedVote = score

      localStorage.setItem(this.voteKey, score)

      const request = new FetchRequest('PUT', this.detection.voteUrl, {
        body: JSON.stringify({ score: score })
      })
      const response = await request.perform()

      if (response.ok) {
        this.$nextTick(() => {
          this.updateTooltip()
          this.tooltip.show()
        })
      }
    },

    loadVote () {
      const value = localStorage.getItem(this.localStorageKey)
      this.recordedVote = value ? parseInt(value) : null
    },

    updateTooltip () {
      this.destroyTooltip()
      this.createTooltip()
    },

    destroyTooltip () {
      this.tooltip.dispose()
      this.tooltip = null
    },

    createTooltip () {
      this.tooltip = new Tooltip(this.$el, {
        title: this.tooltipText,
        placement: 'left'
      })
    }
  },

  computed: {
    hasVoted () {
      return this.recordedVote !== null
    },

    tooltipText () {
      return this.hasVoted ? 'Thanks for helping!' : 'Did we get it right?'
    },

    localStorageKey () {
      return `Detection:${this.detection.id}`
    }
  },

  watch: {
    detection () {
      this.loadVote()
      this.updateTooltip()
    }
  }
}
</script>
