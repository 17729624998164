<template>
  <l-geo-json v-if="rangeData" :geojson="rangeData.range" :optionsStyle="style"></l-geo-json>
</template>

<script>
import { LGeoJson } from 'vue2-leaflet'

import SpeciesRangeQuery from 'queries/SpeciesRange.graphql'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    species: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      rangeData: null
    }
  },

  computed: {
    style () {
      return {
        color: this.species.color
      }
    }
  },

  methods: {
    updateModel (changes) {
      this.$emit('input', {
        ...this.value,
        ...changes
      })
    }
  },

  apollo: {
    rangeData: {
      query: SpeciesRangeQuery,

      variables () {
        return {
          speciesId: this.species.id
        }
      },

      watchLoading (isLoading) {
        this.updateModel({ loading: isLoading })
      }
    }
  },

  watch: {
    species () {
      this.rangeData = null
    },

    rangeData (data) {
      this.updateModel({ hasData: data && data.range })
    }
  },

  components: {
    LGeoJson
  }
}
</script>
