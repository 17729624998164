export const StationTypes = {
  puc: 'PUC',
  birdnetpi: 'BirdNET-Pi',
  stream_youtube: 'Stream (YouTube)',
  stream_audio: 'Stream (Audio)',
  app: 'BirdWeather App'
}

export function stationType (type) {
  return StationTypes[type]
}

export const StationColors = {
  puc: '#35513e',
  birdnetpi: '#25bf21',
  stream_youtube: '#da0101',
  stream_audio: '#2e84cb',
  app: '#ad15b0'
}

export function stationColor (type) {
  return StationColors[type]
}

export const StationIcons = {
  puc: 'puc',
  birdnetpi: 'fixed',
  stream_youtube: 'youtube',
  stream_audio: 'audio',
  app: 'mobile',
  user: 'mobile',
  guest: 'mobile',
  mobile: 'mobile'
}

export function stationIcon (type) {
  return StationIcons[type]
}

