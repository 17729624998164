export function translatedCommonName (species, locale) {
  let commonName = species.commonName

  if (locale !== 'en') {
    const translation = species.translations.find(t => t.locale === locale)
    if (translation) { return translation.commonName }
  }

  return commonName
}
