<template>
  <div class="d-flex align-items-center justify-content-center position-relative">
    <div v-if="$apollo.queries.timeOfDayDetectionCounts.loading" class="text-muted small">
      Loading {{ species.commonName }}...
    </div>

    <div v-else-if="!timeOfDayDetectionCounts.length" class="text-muted small">
      No data for current selection
    </div>

    <template v-else>
      <Chart ref="chart" :options="chartOptions" class="w-100 h-100" />
      <SpeciesCount :count="timeOfDayDetectionCounts[0].count" class="position-absolute top-0 end-0 m-3" />
    </template>
  </div>
</template>

<script>
import SpeciesCount from './SpeciesCount'
import { Chart } from 'highcharts-vue'

import TimeOfDayDetectionCountsQuery from '../queries/TimeOfDayDetectionCounts.graphql'
import { formatTime } from '../lib/formatting'

export default {
  props: {
    species: {
      type: Object,
      required: true
    },

    queryVariables: {
      type: Object
    }
  },

  data () {
    return {
      timeOfDayDetectionCounts: null
    }
  },

  apollo: {
    timeOfDayDetectionCounts: {
      query: TimeOfDayDetectionCountsQuery,

      variables () {
        return {
          ...this.queryVariables,
          speciesId: this.species.id
        }
      }
    }
  },

  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'pie',

          plotBackgroundColor: 'transparent',
          backgroundColor: 'transparent',

          spacingBottom: 10,

          events: {
            load: (e) => {
              const chart = e.target
              this.updateSpeciesImage(chart)
            }
          },

          style: {
            fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
          }
        },

        credits: {
          enabled: false
        },

        plotOptions: {
          pie: {
            allowPointSelect: true,
            borderColor: '#111111',
            borderRadius: 0,
            size: '100%',
            innerSize: '70%',
            startAngle: -180,
            endAngle: 180
          },

          series: {
            dataLabels: {
              enabled: false
            }
          }
        },

        colorAxis: {
          //min: 1,
          max: this.maxCount,
          type: 'logarithmic',
          minColor: '#000022',
          maxColor: '#EEEEFF',
        },

        title: {
          text: ''
        },

        legend: {
          enabled: false
        },

        tooltip: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            return `
              <b>Time:</b> ${formatTime(this.point.hour)}<br />
              <b>Count:</b> ${this.point.colorValue}
            `
          }
        },

        series: [{
          colorKey: 'colorValue',
          data: this.countData.map((count, index) => {
            return { y: 4.167, colorValue: count, hour: index / 2 }
          })
        }]
      }
    },

    countData () {
      const bins = this.timeOfDayDetectionCounts[0].bins
      const result = new Array(48).fill(0)
      bins.forEach((bin) => result[bin.key * 2] = bin.count)
      return result
    },

    maxCount () {
      return Math.max.apply(Math, this.timeOfDayDetectionCounts[0].bins.map((bin) => bin.count))
    }
  },

  methods: {
    removeElement (el) {
      if (!el) return
      el.parentElement.removeChild(el)
    },

    resetSpeciesImage () {
      this.removeElement(document.getElementById('speciesImage'))
      this.removeElement(document.getElementById('speciesImageFill'))
    },

    updateSpeciesImage (chart) {
      if (!chart) return

      this.resetSpeciesImage()

      const r = chart.renderer

      const pattern = r.createElement('pattern').attr({
        id: 'speciesImageFill',
        x: 0,
        y: 0,
        width: '100%',
        height: '100%',
        viewBox: '0 0 200 200'
      }).add(r.defs)

      if (this.species.imageUrl) {
        r.image(this.species.imageUrl, 0, 0, 200, 200).add(pattern)
      }

      r.circle('50%', '50%', '18.5%').attr({
        id: 'speciesImage',
        fill: 'url(#speciesImageFill)',
        stroke: this.species.color,
        'stroke-width': 9
      }).add()
    }
  },

  watch: {
    species (newSpecies) {
      if (newSpecies && this.$refs.chart) {
        this.updateSpeciesImage(this.$refs.chart.chart)
      }
    },

    timeOfDayDetectionCounts (counts) {
      if (counts.length) {
        this.$nextTick(() => this.$refs.chart.chart.reflow())
      }
    }
  },

  components: {
    Chart,
    SpeciesCount
  }
}
</script>
