<template>
  <div class="h-100">
    <template v-if="species.length || loading">
      <SpeciesList ref="list" :species="species" :current="current" :loading="loading" :countLabel="countLabel" @select="$emit('select', $event)" @scrollEnd="loadMore" />
    </template>

    <div v-else-if="this.$slots.empty" class="h-100 species-list-empty">
      <slot name="empty" />
    </div>
  </div>
</template>

<script>
import SpeciesList from './SpeciesList'

export default {
  props: {
    query: {
      type: Object,
      required: true
    },

    queryVariables: {
      type: Object
    },

    perPage: {
      type: Number,
      default: 15
    },

    current: {
      type: Object
    },

    countLabel: {
      type: String,
      default: 'detections'
    }
  },

  data () {
    return {
      hasMore: true,
      offset: 0,
      species: []
    }
  },

  computed: {
    loading () {
      return this.$apollo.queries.species.loading
    }
  },

  methods: {
    loadMore () {
      if (!this.hasMore || this.loading) return

      this.$apollo.queries.species.fetchMore({
        variables: {
          ...this.queryVariables,
          offset: this.offset,
          limit: this.perPage
        },
        updateQuery (previous, { fetchMoreResult }) {
          return {
            species: [
              ...previous.species,
              ...fetchMoreResult.species
            ]
          }
        }
      })
    }
  },

  apollo: {
    species: {
      query () { return this.query },

      fetchPolicy: 'network-only',

      variables () {
        return {
          ...this.queryVariables,
          offset: 0,
          limit: this.perPage
        }
      },

      result ({ data }) {
        const resultCount = data.species.length

        if (resultCount > 0 && resultCount % this.perPage === 0) {
          this.offset += this.perPage
        } else {
          this.hasMore = false
        }

        return data.species
      },

      watchLoading (isLoading) {
        this.$emit('loading', isLoading)
      }
    }
  },

  watch: {
    queryVariables () {
      if (this.$refs.list) {
        this.$refs.list.scrollToTop()
      }

      this.offset = 0
      this.hasMore = true
    }
  },

  components: {
    SpeciesList
  }
}
</script>
