<template>
  <l-circle-marker :lat-lng="center" :radius="radius" :color="color" :fillColor="color" :fillOpacity="1" :weight="0" @click="$emit('click', detectionWithSpecies)" @tooltipopen="loadSpecies = true">
    <l-tooltip ref="tooltip" :options="{ direction: 'left', offset: tooltipOffset }">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 me-2" v-if="species">
          <SpeciesThumbnail class="d-block small" :species="species" />
        </div>

        <div class="flex-grow-1">
          <p class="m-0">
            <template v-if="species">
              {{ species.commonName }}
              <em class="text-muted">({{ species.scientificName }})</em>
            </template>

            <template v-else>
              Loading species info...
            </template>
          </p>

          <p class="m-0 small">
            {{ detection.timestamp | formatDate }}
            @
            {{ detection.timestamp | formatTime }}

            ({{ timeSinceDetection.humanize() }} ago)
          </p>
        </div>
      </div>
    </l-tooltip>
  </l-circle-marker>
</template>

<script>
import SpeciesThumbnail from 'components/SpeciesThumbnail'

import SpeciesInfoQuery from 'queries/SpeciesInfo.graphql'

import { LCircleMarker, LTooltip } from 'vue2-leaflet'

import moment from 'moment'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    },

    radius: {
      type: Number,
      default: 10
    }
  },

  data () {
    return {
      loadSpecies: false,
      species: null
    }
  },

  computed: {
    center () {
      return [this.detection.coords.lat, this.detection.coords.lon]
    },

    color () {
      return this.detection.species.color
    },

    tooltipOffset () {
      return [-this.radius / 2, 0]
    },

    timeSinceDetection () {
      const detectionAt = moment.parseZone(this.detection.timestamp)
      const now = moment(new Date())
      return moment.duration(now.diff(detectionAt))
    },

    detectionWithSpecies () {
      return {
        ...this.detection,

        species: {
          ...this.detection.species,
          ...this.species
        }
      }
    }
  },

  methods: {
    updateTooltip () {
      this.$refs.tooltip.mapObject.update()
    }
  },

  apollo: {
    species: {
      query: SpeciesInfoQuery,

      skip () {
        return !this.loadSpecies
      },

      variables () {
        return {
          id: this.detection.species.id
        }
      },

      result () {
        this.$nextTick(() => this.updateTooltip())
      }
    }
  },

  components: {
    LCircleMarker,
    LTooltip,
    SpeciesThumbnail
  }
}
</script>
