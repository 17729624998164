<template>
  <Modal ref="modal" sizeClass="modal-lg" @show="$emit('show')" @hide="$emit('hide')">
    <template v-slot:title>
      <div class="d-flex align-items-center" v-if="detection.species">
        <SpeciesThumbnail class="small me-2" :species="detection.species" />

        <span>
          {{ detection.species.commonName }}
          <em class="text-muted fw-light">({{ detection.species.scientificName }})</em>
        </span>
      </div>
    </template>

    <Soundscape :soundscape="detection.soundscape" :detection="detection" :species="detection.species" :shareLinks="false" :showNavigator="showNavigator" @next="$emit('next')" @previous="$emit('previous')" />

    <template v-slot:footer>
      <div class="flex-fill d-flex justify-content-between align-items-center">
        <div>
          <button v-if="false" type="button" class="btn p-1" @click="shareViaFacebook" style="color: #4267b2">
            <font-awesome-icon icon="fab fa-facebook-square" />
          </button>

          <a v-if="false" :href="instagramUrl" target="_blank" class="btn p-1" style="color: #f56040">
            <font-awesome-icon icon="fab fa-instagram-square" />
          </a>
        </div>

        <button type="button" class="btn btn-secondary px-4" @click="hide">Close</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal'
import Soundscape from './Soundscape'
import SpeciesThumbnail from './SpeciesThumbnail'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    },

    showNavigator: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    show () { this.$refs.modal.show() },
    hide () { this.$refs.modal.hide() },

    shareViaFacebook () {
      FB.ui({
        method: 'share',
        href: this.detectionUrl,
      }, () => {})
    }
  },

  computed: {
    detectionUrl () {
      return `${document.location.origin}/detections/${this.detection.id}`
    },

    instagramUrl () {
      return `${this.detectionUrl}/instagram.png`
    }
  },

  components: {
    Modal,
    Soundscape,
    SpeciesThumbnail
  }
}
</script>
