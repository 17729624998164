<template>
  <div class="modal fade" ref="model" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog" :class="sizeClass">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><slot name="title">{{ title }}</slot></h5>
          <button type="button" class="btn-close" @click="hide" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <slot />
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn btn-secondary px-4" @click="hide">Close</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  props: {
    title: {
      type: String
    },

    sizeClass: {
      type: String
    }
  },

  mounted () {
    this.modal = new Modal(this.$el)

    this.$el.addEventListener('show.bs.modal', () => this.$emit('show'))
    this.$el.addEventListener('hide.bs.modal', () => this.$emit('hide'))
    this.$el.addEventListener('shown.bs.modal', () => this.$emit('shown'))
    this.$el.addEventListener('hidden.bs.modal', () => this.$emit('hidden'))
  },

  methods: {
    show () {
      this.modal.show()
    },

    hide () {
      this.modal.hide()
    }
  }
}
</script>
