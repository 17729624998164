export const calculateGain = (buffer, sampleRate) => {
  const sliceLen = Math.floor(sampleRate * 0.05)
  const averages = []

  let sum = 0
  for (let i = 0; i < buffer.length; ++i) {
    sum += buffer[i] ** 2
    if (i % sliceLen === 0) {
      sum = Math.sqrt(sum / sliceLen)
      averages.push(sum)
      sum = 0
    }
  }

  averages.sort(function (a, b) { return a - b })
  const a = averages[Math.floor(averages.length * 0.95)]
  const gain = 1 / a / 10.0

  return gain
}
