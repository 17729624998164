<template>
  <div class="station-key" :style="styles">
  </div>
</template>

<script>
import Color from 'color'

import { stationColor } from '../lib/station'

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },

  computed: {
    borderColor () {
      return stationColor(this.type)
    },

    fillColor () {
      return Color(stationColor(this.type)).alpha(0.85)
    },

    styles () {
      return {
        borderColor: this.borderColor,
        backgroundColor: this.fillColor
      }
    }
  }
}
</script>
