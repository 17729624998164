<template>
  <SpeciesMarker ref="marker" :zoom="zoom" :species="detection.species" :coords="detection.coords" @remove="$emit('expire', detection)" @click="$emit('click', detection)">
    <template v-slot:tooltip>
      <strong>{{ translatedCommonName }}</strong>
      <span class="text-muted fst-italic">({{ detection.species.scientificName }})</span>
    </template>
  </SpeciesMarker>
</template>

<script>
import SpeciesMarker from './SpeciesMarker'

import { mapState } from 'vuex'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    },

    zoom: {
      type: Number,
      default: 3
    },

    timeout: {
      type: Number,
      default: 4000
    }
  },

  mounted () {
    setTimeout(() => {
      this.$refs.marker.fadeOut()
    }, this.timeout)
  },

  computed: {
    ...mapState(['locale']),

    translatedCommonName () {
      let commonName = this.detection.species.commonName

      if (this.locale !== 'en') {
        const translation = this.detection.species.translations.find(t => t.locale === this.locale)
        if (translation) { return translation.commonName }
      }

      return commonName
    }
  },

  components: {
    SpeciesMarker
  }
}
</script>
