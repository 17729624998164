<template>
  <div class="data-layer" :class="{ disabled: disabled }">
    <Checkbox class="flex-fill" v-model="isActive">
      <p>
        {{ name }}
        <font-awesome-icon v-if="loading" icon="spinner" class="text-muted" spin />
      </p>
      <p v-if="caption" class="small text-muted">{{ caption }}</p>
    </Checkbox>

    <div v-if="hasDefaultSlot" class="flex-shrink-0 ms-3">
      <slot />
    </div>
  </div>
</template>

<script>
import Checkbox from './Checkbox'

export default {
  model: {
    prop: 'active',
    event: 'toggle'
  },

  props: {
    active: {
      type: Boolean
    },

    name: {
      type: String,
      required: true
    },

    caption: {
      type: String
    },

    loading: {
      type: Boolean,
      default: false
    },

    enabled: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isActive: {
      get() { return this.active },
      set(active) { this.$emit('toggle', active) }
    },

    disabled () {
      return !this.enabled
    },

    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },

  components: {
    Checkbox
  }
}
</script>
