<template>
  <div class="station-stats">
    <div class="dropup d-flex" v-if="showStations" >
      <StationStat caption="STATIONS" :value="counts.stations" data-bs-toggle="dropdown" data-bs-auto-close="outside" style="cursor: pointer">
        <font-awesome-icon icon="caret-up" size="xs" class="text-muted" />
      </StationStat>

      <ul class="dropdown-menu dropdown-menu-end station-stats-layers">
        <li v-for="(label, type) in StationTypes" :key="type">
          <a href="#" class="dropdown-item small d-flex align-items-center" :class="{ active: layers[type] }" @click.prevent="$emit('toggleLayer', type)">
            <StationKey :type="type" />
            <span class="ms-2 me-3">{{ label }}</span>
            <small class="text-muted ms-auto">{{ stationCount(type) }}</small>
          </a>
        </li>
      </ul>
    </div>

    <StationStat caption="DETECTIONS" :value="counts.detections" v-if="showDetections" />
    <StationStat caption="BIRDNET" :value="counts.birdnet" v-if="showBirdNET" />
    <StationStat caption="EBIRD" :value="ebirdCount" :loading="ebirdLoading" v-if="showEbird" />
    <StationStat caption="SPECIES" :value="counts.species" v-if="showSpecies" />

    <div class="loading" v-if="loading">
      <font-awesome-icon icon="spinner" class="text-muted" spin />
    </div>
  </div>
</template>

<script>
import StationStat from './StationStat'
import StationKey from './StationKey'

import { StationTypes } from '../lib/station'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    counts: {
      type: Object,
      required: true
    },

    stations: {
      type: Boolean,
      default: true
    },

    birdnet: {
      type: Boolean,
      default: true
    },

    ebird: {
      type: Boolean,
      default: false
    },

    ebirdLoading: {
      type: Boolean,
      default: false
    },

    ebirdCount: {
      type: Number
    },

    layers: {
      type: Object
    }
  },

  created () {
    this.StationTypes = StationTypes
  },

  methods: {
    stationCount (type) {
      if (!this.counts.breakdown) return 0

      if (type === 'app') {
        return this.stationCount('user') + this.stationCount('guest')
      } else {
        const station = this.counts.breakdown.stations.find((s) => s.type === type)
        return station ? station.count : 0
      }
    }
  },

  computed: {
    showStations () {
      return this.stations && typeof this.counts.stations !== 'undefined'
    },

    showDetections () {
      return this.stations && typeof this.counts.detections !== 'undefined'
    },

    showBirdNET () {
      return this.birdnet && typeof this.counts.birdnet !== 'undefined'
    },

    showEbird () {
      return this.ebird && typeof this.ebirdCount !== 'undefined'
    },

    showSpecies () {
      return this.stations && typeof this.counts.species !== 'undefined'
    }
  },

  components: {
    StationKey,
    StationStat
  }
}
</script>
