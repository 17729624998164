<template>
  <date-range-picker ref="picker" :date-range="dateRange" @select="selectDateRange" control-container-class="" :opens="opensDirection" :auto-apply="autoApply" :min-date="new Date(2021, 9, 1)" :max-date="new Date()" :append-to-body="true">
    <template v-slot:input>
      <slot />
    </template>

    <template v-slot:ranges>
      <div class="ranges">
        <ul>
          <li v-for="period in allPeriods" :key="period ? `${period.count}-${period.unit}` : null" @click="selectPeriod(period)" :class="{ active: isCurrent(period) }">
            {{ periodDescription(period) }}
          </li>
        </ul>
      </div>
    </template>

    <template v-slot:footer><div /></template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'

import { periodDescription } from '../lib/period'

import moment from 'moment'
import { isEqual } from 'lodash'

export default {
  props: {
    value: {
      type: Object
    },

    opensDirection: {
      type: String,
      default: 'left'
    },

    autoApply: {
      type: Boolean,
      default: false
    },

    periods: {
      type: Array,
      default: () => [
        { count: 1, unit: 'hour' },
        { count: 3, unit: 'hour' },
        { count: 6, unit: 'hour' },
        { count: 12, unit: 'hour' },
        { count: 24, unit: 'hour' },
        { count: 3, unit: 'day' },
        { count: 7, unit: 'day' },
        { count: 2, unit: 'week' },
        { count: 1, unit: 'month' }
      ]
    },

    additionalPeriods: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    allPeriods () {
      return [
        ...this.periods,
        ...this.additionalPeriods
      ]
    },

    dateRange () {
      if (this.value.from && this.value.to) {
        return { startDate: this.value.from, endDate: this.value.to }
      } else if (this.value.count) {
        const now = moment()
        const start = moment().subtract(this.value.count, `${this.value.unit}s`)
        return { startDate: start, endDate: now }
      } else {
        return {}
      }
    }
  },

  methods: {
    periodDescription,

    isCurrent (period) {
      return isEqual(this.value, period)
    },

    selectDateRange (range) {
      this.$emit('input', {
        from: moment(range.startDate).startOf('day').toDate(),
        to: moment(range.endDate).endOf('day').toDate()
      })
    },

    selectPeriod (period) {
      this.$emit('input', period)

      if (this.autoApply) {
        this.$refs.picker.open = false
      }
    }
  },

  components: {
    DateRangePicker
  }
}
</script>
