<template>
  <div class="species-list py-1 h-100 overflow-auto" :class="{ 'has-selection': !!current, 'loading': loading }" @scroll="onScroll">
    <SpeciesListItem v-for="item in species" :key="item.species.id" :species="item.species" :count="item.count" :breakdown="item.breakdown" :averageProbability="item.averageProbability" :active="isCurrent(item.species)" :countLabel="countLabel" @select="selectSpecies" />
  </div>
</template>

<script>
import SpeciesListItem from './SpeciesListItem'

const SCROLL_THRESHOLD = 10

export default {
  props: {
    species: {
      type: Array,
      default: () => []
    },

    countLabel: {
      type: String,
      default: 'detections'
    },

    loading: {
      type: Boolean,
      default: true
    },

    current: {
      type: Object
    }
  },

  methods: {
    selectSpecies (species) {
      if (species === this.current) {
        this.$emit('select', null)
      } else {
        this.$emit('select', species)
      }
    },

    isCurrent (species) {
      return this.current && species.id === this.current.id
    },

    onScroll () {
      if (this.isAtScrollEnd()) {
        this.$emit('scrollEnd')
      }
    },

    isAtScrollEnd () {
      return this.$el.scrollTop + this.$el.clientHeight + SCROLL_THRESHOLD >= this.$el.scrollHeight
    },

    scrollToTop () {
      this.$el.scrollTop = 0
    }
  },

  watch: {
    species () {
      this.$nextTick(() => this.onScroll())
    }
  },

  components: {
    SpeciesListItem
  }
}
</script>
