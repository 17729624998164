import Vue from 'vue'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { ApolloLink } from 'apollo-link'

import { createConsumer } from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'

import VueApollo from 'vue-apollo'

Vue.use(VueApollo)

export const HOST = process.env.APOLLO_HOST || 'https://app.birdweather.com'
export const CABLE_URL = process.env.ACTIONCABLE_URL || `${HOST}/cable`

const cable = createConsumer(CABLE_URL)

const httpLink = createHttpLink({
  uri: `${HOST}/graphql`
})

const cache = new InMemoryCache()

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  )
}

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const apolloClient = new ApolloClient({
  link: link,
  cache
})

export default new VueApollo({
  defaultClient: apolloClient
})
