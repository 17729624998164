<template>
  <li class="nav-item">
    <a class="nav-link" :class="{ active: active }" href="#" @click.prevent="$emit('click')" :title="title" ref="link">
      <slot />
    </a>
  </li>
</template>

<script>
import Tooltip from 'bootstrap/js/dist/tooltip'

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    },

    tooltipPlacement: {
      type: String,
      default: 'right'
    }
  },

  mounted () {
    if (this.title) {
      this.tooltip = new Tooltip(this.$refs.link, { placement: this.tooltipPlacement, html: true, trigger: 'hover' })
    }
  },

  destroyed () {
    if (this.tooltip) {
      this.tooltip.dispose()
    }
  },
}
</script>
