<template>
  <div>
    <div class="toasts-container">
      <DetectionNotification v-for="detection in toasts" :key="detection.id" :detection="detection" @click="$emit('click', detection)" @remove="removeToast" />
    </div>

    <ExpiringSpeciesMarker v-for="detection in markers" :key="detection.id" :detection="detection" :zoom="zoom" @click="$emit('click', detection)" @expire="removeMarker" />
  </div>
</template>

<script>
import DetectionNotification from 'components/DetectionNotification'
import ExpiringSpeciesMarker from 'components/markers/ExpiringSpeciesMarker'

import NewDetectionsQuery from 'queries/NewDetections.graphql'

import booleanPointInPolygon from '@turf/boolean-point-in-polygon'

export default {
  props: {
    bounds: {
      type: Object
    },

    focusArea: {
      type: Object
    },

    zoom: {
      type: Number,
      default: 3
    }
  },

  data () {
    return {
      markers: [],
      toasts: []
    }
  },

  methods: {
    newDetection (detection) {
      if (document.hidden) {
        // Dont' show notifications within an inactive tab
        return
      }

      const withinBounds = this.withinBounds(detection.coords)
      const withinFocusArea = this.withinFocusArea(detection.coords)

      const visible = withinBounds && withinFocusArea

      console.log(`[${visible ? 'VISIBLE' : 'HIDDEN'}] New detection: ${detection.species.commonName} (${detection.species.scientificName}) at (${detection.coords.lat.toFixed(3)}, ${detection.coords.lon.toFixed(3)}) [SCORE: ${detection.score.toFixed(3)}] @ ${detection.timestamp}`)

      this.markers.push(detection)

      if (visible && this.toasts.length < 8 && !this.toasts.some(d => this.detectionMatches(d, detection))) {
        this.toasts.push(detection)
      }
    },

    removeMarker (detection) {
      const index = this.markers.indexOf(detection)
      if (index >= 0) this.markers.splice(index, 1)
    },

    removeToast (detection) {
      const index = this.toasts.indexOf(detection)
      if (index >= 0) this.toasts.splice(index, 1)
    },

    detectionMatches (d1, d2) {
      return d1.coords.lat === d2.coords.lat &&
        d1.coords.lon === d2.coords.lon &&
        d1.species.commonName === d2.species.commonName &&
        d1.species.scientificName == d2.species.scientificName
    },

    withinBounds (coords) {
      return !this.bounds || this.bounds.contains({ lat: coords.lat, lng: coords.lon })
    },

    withinFocusArea (coords) {
      if (!this.focusArea) return true

      const point = [coords.lon, coords.lat]
      return booleanPointInPolygon(point, this.focusArea.features[0])
    }
  },

  apollo: {
    $subscribe: {
      newDetection: {
        query: NewDetectionsQuery,

        fetchPolicy: 'no-cache',

        result ({ data }) {
          if (data.newDetection) {
            const detection = data.newDetection.detection
            this.newDetection(detection)
          }
        }
      }
    }
  },

  components: {
    DetectionNotification,
    ExpiringSpeciesMarker
  }
}
</script>
