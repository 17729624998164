<template>
  <div class="d-flex align-items-center">
    <div class="flex-shrink-0">
      <SpeciesThumbnail :species="species" class="small" />
    </div>

    <div class="flex-grow-1 ms-2">
      <h6 class="fw-normal m-0">
        {{ species.commonName }}

        <em class="text-muted small">({{ species.scientificName }})</em>

        <span class="species-buttons">
          <a v-if="species.birdweatherUrl" :href="species.birdweatherUrl" class="species-button" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" fill="none" viewBox="0 0 1000 1000" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#000" d="M666.153 813.667l-11.178 45.694c-33.534 13.235-60.321 23.309-80.268 30.235-19.968 6.948-43.17 10.404-69.606 10.404-40.596 0-72.172-9.945-94.693-29.698-22.521-19.832-33.785-44.963-33.785-75.449 0-11.802.818-23.941 2.517-36.309 1.714-12.382 4.438-26.328 8.167-41.915l41.908-148.332c3.728-14.203 6.897-27.661 9.435-40.388 2.574-12.648 3.815-24.285 3.815-34.76 0-18.943-3.922-32.193-11.73-39.642-7.808-7.428-22.707-11.2-44.827-11.2-10.833 0-21.968 1.735-33.318 5.091-11.401 3.37-21.144 6.625-29.325 9.651l11.206-45.73c27.454-11.178 53.696-20.75 78.798-28.694 25.102-7.966 48.82-11.953 71.255-11.953 40.316 0 71.427 9.744 93.273 29.232 21.847 19.502 32.767 44.784 32.767 75.937 0 6.446-.717 17.803-2.259 34.036-1.505 16.268-4.309 31.174-8.396 44.733L558.18 702.346c-3.42 11.867-6.496 25.432-9.17 40.697-2.76 15.164-4.08 26.751-4.08 34.523 0 19.617 4.374 33.011 13.143 40.13 8.833 7.12 24.048 10.662 45.658 10.662 10.145 0 21.689-1.8 34.516-5.334 12.791-3.535 22.119-6.64 27.906-9.357zm10.582-620.243c0 25.74-9.701 47.723-29.196 65.799-19.445 18.14-42.876 27.217-70.286 27.217-27.497 0-50.986-9.077-70.653-27.217-19.631-18.083-29.469-40.059-29.469-65.799 0-25.69 9.838-47.708 29.469-66.013C526.231 109.134 549.763 100 577.253 100c27.403 0 50.841 9.156 70.286 27.411 19.51 18.305 29.196 40.331 29.196 66.013z"/></svg>
          </a>

          <a v-if="species.ebirdUrl" :href="species.ebirdUrl" class="species-button" target="_blank">
            <svg class="LogoLabSapsucker LogoLabSapsucker--xs" viewBox="0 0 100 58" aria-labelledby="logo-lab-sapsucker-title" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g class="LogoLabSapsucker-sapsucker" fill="none" fill-rule="evenodd"><path fill="#FFF" d="M95.947 8.519c-.79-.284-3.144-1.022-4.149-1.337-1.007-.32-3.866-1.145-4.7-1.396a8.265 8.265 0 00-.875-.977C83.449 1.867 79.229.112 74.928.112c-4.887 0-8.546 1.607-9.8 2.24H1.325v1.8c0 2.708 3.132 4.75 7.287 4.75h17.584l14.236 10.024v15.376c0 7.584 4.011 14.444 10.724 18.359 5.619 3.278 9.007 3.54 12.85 3.54.276 0 .552-.003.837-.005.287 0 .579-.003.88-.003h1.805v-32l1.203-.883 15.641-11.49s8.221-.508 10.306-.642a69.848 69.848 0 003.374-.29c.837-.099 1.178-.363 1.178-.58 0-.261-.155-.364-.35-.51-.161-.122-1.402-.726-2.932-1.278z"/><path fill="#000" d="M96.306 9.7S88.884 7.747 86.03 7.497c0 0-11.293 6.073-14.456 8.114-3.168 2.04-3.77 5.107-3.91 6.25L83.782 10.02h12.63c.321 0-.107-.322-.107-.322z"/><path fill="#000" d="M70.201 14.489c3.307-2.2 14.725-8.43 14.725-8.43l.016.018c-1.957-2.093-5.513-4.165-10.012-4.165-5.533 0-9.347 2.24-9.347 2.24H3.133c0 1.67 2.527 2.947 5.481 2.947h18.16l15.468 10.898v16.306c0 7.079 3.73 13.25 9.832 16.807 6.098 3.557 9.159 3.287 13.65 3.287V23.284c0-2.74.828-6.365 4.477-8.795zm9.858-7.59a1.136 1.136 0 01-2.27 0 1.136 1.136 0 012.27 0zm-14.285 4.72l7.08-3.905 3.113.255-10.193 5.636V11.62z"/><path fill="#F0E614" d="M26.775 7.101h26.03c-5.82 0-10.563 4.66-10.563 10.898L26.775 7.1z"/><path fill="#E60000" d="M83.508 6.842c-2.058-1.746-5.297-3.343-9.14-3.343V1.923l.562-.011c4.494 0 8.04 2.061 9.996 4.147l-1.418.783zm.271 3.18L72.5 18.31s-.134-1.212 1.564-2.578C75.76 14.366 86.03 7.499 86.03 7.499l-2.25 2.522z"/><path fill="#FFF" d="M60.986 31.204v-5.545c0-2.33-.954-3.944-3.13-5.478l-9.22-6.493a6.339 6.339 0 00-1.66 4.315 6.425 6.425 0 002.558 5.125l11.452 8.076z"/></g></svg>
          </a>

          <a v-if="species.wikipediaUrl" :href="species.wikipediaUrl" class="species-button" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="2560" height="1980" viewBox="0 0 2560 1980" version="1.0" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1572.185 1627.01l-266.003-626.503c-105.393 206.784-222.162 421.675-322.094 626.176-.553 1.06-48.294.437-48.315-.191-152.725-356.389-311.056-710.43-464.512-1066.543-35.591-87.053-160.204-227.093-245.46-226.297-.031-10.115-.503-32.653-.641-46.322l525.74-.249-.358 45.713c-61.746 2.878-168.424 42.263-140.81 110.427 74.132 159.979 336.5 779.827 407.409 937.181 49.5-96.843 187.673-355.01 244.542-464.167-44.593-91.508-191.986-433.216-236.175-519.293-33.326-56.095-116.953-62.97-181.375-63.931.021-14.413.802-25.442.464-45.136l462.217 1.424v41.97c-62.594 1.738-121.822 25.018-95.017 84.823 62.177 129.066 98.448 220.924 155.617 340.281 18.26-34.952 111.61-226.606 156.165-327.802 26.936-67.217-13.272-92.449-125.89-95.476 1.491-11.06.51-33.271 1.455-43.855 143.72-.541 360.731-1.044 399.16-1.624l.21 43.678c-73.362 2.846-149.245 41.939-188.892 102.59l-192.213 398.637c21.11 52.788 205.884 463.286 225.361 508.814l397.406-917.151c-28.264-74.279-118.47-90.861-153.735-91.698.253-11.81.273-29.855.372-44.886l414.848 3.156.563 2.096-.71 39.442c-91.01 2.743-147.31 51.405-180.948 131.187-82.667 186.738-335.389 778.195-504.065 1163.66-.221.212-44.219-.065-44.316-.13z"/></svg>
          </a>
        </span>
      </h6>
    </div>
  </div>
</template>

<script>
import SpeciesThumbnail from './SpeciesThumbnail'

export default {
  props: {
    species: {
      type: Object,
      required: true
    }
  },

  components: {
    SpeciesThumbnail
  }
}
</script>
