<template>
  <div>
    <SpeciesMapLayer v-if="species" :species="species" :period="period" :zoom="zoom" :visibleTypes="visibleStationTypes" />

    <MapLayer v-for="type in stationTypes" :key="type" :name="stationType(type)" :pane="type" :visible="value.types[type]">
      <StationMarker v-for="station in stationsByType[type]" :key="station.id" :station="station" :zoom="zoom" :period="period" :selected="isSelected(station)" :focus="focusStation(station)" @click="$emit('select:station', $event)" />
    </MapLayer>

    <DetectionsMapLayer v-if="station && station.portableDetections" :station="station" :species="species" :queryVariables="queryVariables" :zoom="zoom" />

    <DetectionNotifications v-if="liveDetections" :bounds="bounds" :zoom="zoom" :focusArea="focusArea" @click="$emit('select:detection', $event)" />
  </div>
</template>

<script>
import DetectionNotifications from 'components/DetectionNotifications'
import DetectionsMapLayer from 'components/layers/DetectionsMapLayer'
import MapLayer from 'components/layers/MapLayer'
import SpeciesMapLayer from 'components/layers/SpeciesMapLayer'
import StationMarker from 'components/markers/StationMarker'

import { StationTypes, stationType } from 'lib/station'

import StationsQuery from 'queries/Stations.graphql'

import booleanPointInPolygon from '@turf/boolean-point-in-polygon'

import { mapState } from 'vuex'

const STATION_REFRESH_INTERVAL = 5 * 60 * 1000 // 5 minutes

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    species: {
      type: Object
    },

    station: {
      type: Object
    },

    period: {
      type: Object
    },

    zoom: {
      type: Number
    },

    bounds: {
      type: Object
    },

    focusArea: {
      type: Object
    },

    queryVariables: {
      type: Object
    }
  },

  data () {
    return {
      loaded: false,
      stationsById: {},
      currentDetection: null,

      focusStationCache: {}
    }
  },

  computed: {
    ...mapState(['liveDetections']),

    stations () {
      return Object.values(this.stationsById)
    },

    stationTypes () {
      return Object.keys(StationTypes)
    },

    stationsByType () {
      return this.stations.reduce((result, station) => {
        const type = this.normalizedStationType(station.type)

        result[type] = result[type] || []
        result[type].push(station)
        return result
      }, {})
    },

    visibleStationTypes () {
      return Object.keys(this.value.types).filter(type => this.value.types[type])
    },

    currentDetectionWithStation () {
      if (!this.currentDetection) return

      return {
        ...this.currentDetection,

        station: {
          ...this.currentDetection.station,
          ...this.station
        }
      }
    }
  },

  methods: {
    stationType,

    setupPanes (map) {
      map.createPane('mobile')

      this.stationTypes.reverse().forEach((type) => {
        map.createPane(type)
      })
    },

    findStationById (id) {
      return this.stationsById[id]
    },

    refreshStations () {
      this.$apollo.queries.stations.refetch()
    },

    normalizedStationType (type) {
      return type === 'user' || type === 'guest' ? 'mobile' : type
    },

    selectDetection (detection) {
      // this.currentDetection = detection
      // this.$nextTick(() => this.$refs.modal.show())

      this.$emit('select:detection', detection)
    },

    isSelected (station) {
      return this.station?.id == station.id
    },

    focusStation (station) {
      if (!this.focusArea) return true

      if (station.id in this.focusStationCache) {
        return this.focusStationCache[station.id]
      } else {
        const point = [station.coords.lon, station.coords.lat]
        const focus = booleanPointInPolygon(point, this.focusArea.features[0])
        this.focusStationCache[station.id] = focus
        return focus
      }
    }
  },

  apollo: {
    stations: {
      query: StationsQuery,

      variables () {
        return this.queryVariables
      },

      pollInterval: STATION_REFRESH_INTERVAL,

      update (data) {
        if (!this.loaded) {
          this.loaded = true

          this.$nextTick(() => {
            this.$emit('loaded')
          })
        }

        return data.stations
      },

      manual: true,

      result (result) {
        const stations = result.data.stations.nodes

        const newStations = {}

        for (const station of stations) {
          newStations[station.id] = station
        }

        this.stationsById = {
          ...this.stationsById,
          ...newStations
        }
      }
    }
  },

  watch: {
    focusArea () {
      this.focusStationCache = {}
    }
  },

  components: {
    DetectionNotifications,
    DetectionsMapLayer,
    MapLayer,
    SpeciesMapLayer,
    StationMarker
  }
}
</script>
