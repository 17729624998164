export function formatTime (hour) {
  const period = hour < 12 ? 'am' : 'pm'
  const h = Math.floor(Math.floor(hour) === 0 ? 12 : (Math.floor(hour) > 12 ? hour - 12 : hour))
  const m = hour % 1 === 0 ? '00' : '30'

  return `${h}:${m}${period}`
}

export function formatCount (count, singular, plural) {
  if (count === 1) {
    return `1 ${singular}`
  } else if (typeof count === 'number') {
    plural = plural || (singular.match(/s$/) ? singular : `${singular}s`)
    return `${count.toLocaleString()} ${plural}`
  }
}
