import Vue from 'vue'

import 'core/mapping'
import 'core/timestamps'
import 'core/charts'
import 'core/fontawesome'
import settings from 'core/settings'

import apolloProvider, { CABLE_URL } from 'core/apollo'

import ActionCableVue from 'actioncable-vue'
import PortalVue from 'portal-vue'
import VueVisible from 'vue-visible'
import VueYoutube from 'vue-youtube'

import Multiselect from 'vue-multiselect'

import App from '../dashboard/App.vue'

Vue.use(PortalVue)
Vue.use(VueVisible)
Vue.use(VueYoutube)
Vue.use(ActionCableVue, { connectionUrl: CABLE_URL })

Vue.component('multiselect', Multiselect)

const parseJSON = (str) => str ? JSON.parse(str) : undefined
const parseBoolean = (str) => str === 'true'

document.addEventListener('DOMContentLoaded', () => {
  const target = document.getElementById('app')
  if (!target) return

  new Vue({
    apolloProvider,
    store: settings,
    render: h => h(App, {
      props: {
        initialStation: parseJSON(target.dataset.station),
        selectedDetection: parseJSON(target.dataset.detection),
        selectedSoundscape: parseJSON(target.dataset.soundscape),
        center: parseJSON(target.dataset.center),
        showCounts: parseBoolean(target.dataset.showCounts),
        showTopSpecies: parseBoolean(target.dataset.showTopSpecies),
        enablePeriodSelection: parseBoolean(target.dataset.enablePeriodSelection)
      }
    })
  }).$mount(target)
})
