import Vue from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'

import { faSpinner, faCalendarAlt, faChevronDown, faCaretUp, faSortNumericDown, faThumbsUp, faThumbsDown, faDownload, faPlay, faShare, faCloudSunRain, faVideo, faIdBadge, faFlag, faStar, faGear, faCircleInfo, faLayerGroup, faFilter } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram, faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

library.add(faSpinner, faCalendarAlt, faChevronDown, faCaretUp, faSortNumericDown, faThumbsUp, faThumbsDown, faDownload, faPlay, faShare, faCloudSunRain, faFacebookF, faFacebookSquare, faInstagram, faInstagramSquare, faVideo, faIdBadge, faFlag, faStar, faGear, faCircleInfo, faLayerGroup, faFilter)

Vue.component('font-awesome-icon', FontAwesomeIcon)
