<template>
  <div class="d-flex flex-column">
    <DonutPlot class="flex-fill" :species="species" :queryVariables="queryVariables" />
    <DetectionHistogram style="flex: 0 0 20%; min-height: 0" :species="species" :queryVariables="queryVariables" :period="period" />
  </div>
</template>

<script>
import DetectionHistogram from './DetectionHistogram'
import DonutPlot from './DonutPlot'

export default {
  props: {
    species: {
      type: Object
    },

    queryVariables: {
      type: Object
    },

    period: {
      type: Object
    }
  },

  components: {
    DetectionHistogram,
    DonutPlot
  }
}
</script>
