<template>
  <div class="overflow-auto">
    <div class="p-4">
      <h3 class="mb-3 font-serif fs-italic">{{ species.commonName }}</h3>

      <div v-html="formattedSummary" class="mb-3"></div>

      <a :href="species.wikipediaUrl" target="_blank">Read more on Wikipedia</a>

      <p class="small text-muted fst-italic mt-3 mb-0">
        <strong>Image credit:</strong>
        <span v-html="imageCredits"></span>
      </p>
    </div>
  </div>
</template>

<script>
import * as sanitizeHtml from 'sanitize-html';

export default {
  props: {
    species: {
      type: Object,
      required: true
    }
  },

  computed: {
    formattedSummary () {
      return this.species.wikipediaSummary.replace(/\n/, "<br /><br />")
    },

    imageCredits () {
      let result = '&copy; '

      if (this.species.imageCredit) {
        result += sanitizeHtml(this.species.imageCredit, {
          allowedTags: ['a'],
          allowedAttributes: {
            a: ['href', 'rel', 'target']
          }
        })
      } else {
        result += 'Wikimedia Commons'
      }

      if (this.species.imageLicense) {
        let license = this.species.imageLicense

        if (this.species.imageLicenseUrl) {
          license = `<a href="${this.species.imageLicenseUrl}" target="_blank" rel="nofollow">${license}</a>`
        }

        result += ` / ${license}`
      }

      return result
    }
  }
}
</script>
