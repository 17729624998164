<template>
  <div class="d-flex" style="height: 4px">
    <div :style="sectionStyle('#f94144', breakdown.unlikely)"></div>
    <div :style="sectionStyle('#f9c74f', breakdown.uncertain)"></div>
    <div :style="sectionStyle('#f8961e', breakdown.veryLikely)"></div>
    <div :style="sectionStyle('#90be6d', breakdown.almostCertain)"></div>
  </div>
</template>

<script>
export default {
  props: {
    breakdown: {
      type: Object,
      required: true
    }
  },

  methods: {
    sectionStyle (background, flex) {
      return {
        backgroundColor: background,
        flex: flex
      }
    }
  }
}
</script>
