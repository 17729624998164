<template>
  <div class="d-flex flex-column">
    <template v-if="species">
      <div class="flex-fill d-flex flex-row">
        <div class="flex-shrink-0 nav nav-pills text-center border-end flex-column">
          <NavItem :active="isActiveTab('detections')" @click="selectTab('detections')" title="Detections"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 103.27" v-bind:svg-inline="''" v-bind:class="'tab-icon padding-sm'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M34.56 13.93C22.46 9.3 10.93 8.29 0 11c14.14.58 25.82 2.91 32.93 8.48 8 13.71 1 51.07 46 52.17 8.21 16.4 17.56 28.2 29 31.63-3.12-10.1-19.61-44.28-28.7-49.58 11.41-.79 13.17-3.57 6.2-8.15 22.83-.9 27.85-4.52 14-10.76 17.12-3.41 23.17-11.3 14.67-14.34 7.73-5.78 10.23-13.86 7.95-20.45-14 13.49-34.77 18.34-54.93 30.58C61.49 11.89 48 6.28 34.56 13.93zm12 1.39a1.36 1.36 0 01.29 0 2.49 2.49 0 00-.87-.16 2.45 2.45 0 101.55.56 1.27 1.27 0 01.35.89 1.32 1.32 0 11-1.32-1.32z" fill-rule="evenodd"/></svg></NavItem>
          <NavItem :active="isActiveTab('soundscapes')" @click="selectTab('soundscapes')" title="Soundscapes"><svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 96.65" v-bind:svg-inline="''" v-bind:class="'tab-icon padding-md'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 22.84h25.47L58.17 1A3.44 3.44 0 0163 1a3.39 3.39 0 011 2.44V93.2a3.46 3.46 0 01-5.93 2.41L36.65 77.49H11a11 11 0 01-11-11V33.83a11 11 0 0111-11zm65.12 15a3.22 3.22 0 116.1-2 43.3 43.3 0 011.56 13.27c-.09 4.76-.78 9.44-2.13 12.21a3.23 3.23 0 11-5.8-2.83c.93-1.92 1.43-5.59 1.5-9.48a37.13 37.13 0 00-1.23-11.12zm16.64-12a3.23 3.23 0 016-2.48c3 7.18 4.61 16.23 4.75 25.22s-1.17 17.72-4 24.77a3.22 3.22 0 11-6-2.4C96 64.64 97.15 56.66 97 48.6s-1.58-16.36-4.28-22.81zm16.09-10.23a3.22 3.22 0 115.8-2.8 86.65 86.65 0 018.24 36.44c.09 12.22-2.37 24.39-7.73 34.77a3.22 3.22 0 01-5.73-3c4.88-9.43 7.11-20.56 7-31.77a80 80 0 00-7.6-33.69zM37.89 29.74H11a4.11 4.11 0 00-4.1 4.09v32.68A4.11 4.11 0 0011 70.6h26.9s2 .69 2.21.83L57.16 85.8v-74L40.52 28.53a3.46 3.46 0 01-2.63 1.21z"/></svg></NavItem>
          <NavItem :active="isActiveTab('wikipedia')" @click="selectTab('wikipedia')" title="Wikipedia"><svg xmlns="http://www.w3.org/2000/svg" width="2560" height="1980" viewBox="0 0 2560 1980" version="1.0" v-bind:svg-inline="''" v-bind:class="'tab-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1572.185 1627.01l-266.003-626.503c-105.393 206.784-222.162 421.675-322.094 626.176-.553 1.06-48.294.437-48.315-.191-152.725-356.389-311.056-710.43-464.512-1066.543-35.591-87.053-160.204-227.093-245.46-226.297-.031-10.115-.503-32.653-.641-46.322l525.74-.249-.358 45.713c-61.746 2.878-168.424 42.263-140.81 110.427 74.132 159.979 336.5 779.827 407.409 937.181 49.5-96.843 187.673-355.01 244.542-464.167-44.593-91.508-191.986-433.216-236.175-519.293-33.326-56.095-116.953-62.97-181.375-63.931.021-14.413.802-25.442.464-45.136l462.217 1.424v41.97c-62.594 1.738-121.822 25.018-95.017 84.823 62.177 129.066 98.448 220.924 155.617 340.281 18.26-34.952 111.61-226.606 156.165-327.802 26.936-67.217-13.272-92.449-125.89-95.476 1.491-11.06.51-33.271 1.455-43.855 143.72-.541 360.731-1.044 399.16-1.624l.21 43.678c-73.362 2.846-149.245 41.939-188.892 102.59l-192.213 398.637c21.11 52.788 205.884 463.286 225.361 508.814l397.406-917.151c-28.264-74.279-118.47-90.861-153.735-91.698.253-11.81.273-29.855.372-44.886l414.848 3.156.563 2.096-.71 39.442c-91.01 2.743-147.31 51.405-180.948 131.187-82.667 186.738-335.389 778.195-504.065 1163.66-.221.212-44.219-.065-44.316-.13z"/></svg></NavItem>
        </div>

        <div class="ratio ratio-16x9" style="min-height: calc(256px + 32px + 2 * 1rem + 42px + 0.5rem)">
          <SpeciesDetectionsView v-if="isActiveTab('detections')" class="flex-fill overflow-hidden" :species="species" :queryVariables="queryVariables" :period="period" />
          <SoundscapeList v-if="isActiveTab('soundscapes')" class="flex-fill" :species="species" :queryVariables="queryVariables" />
          <Wikipedia v-if="isActiveTab('wikipedia')" class="flex-fill" :species="species" />
        </div>
      </div>
    </template>

    <div v-else class="flex-fill text-muted small d-flex align-items-center justify-content-center ratio ratio-16x9" style="padding-top: calc(3.5rem + 0.5rem * 2 + 1px); padding-bottom: calc(24px + 0.5rem * 2 + 1px);">
      No species selected.
    </div>
  </div>
</template>

<script>
import NavItem from './NavItem'
import SoundscapeList from './SoundscapeList'
import SpeciesDetectionsView from './SpeciesDetectionsView'
import Wikipedia from './Wikipedia'

export default {
  props: {
    species: {
      type: Object
    },

    queryVariables: {
      type: Object
    },

    period: {
      type: Object
    }
  },

  data () {
    return {
      activeTab: 'detections'
    }
  },

  methods: {
    selectTab (tab) {
      this.activeTab = tab
    },

    isActiveTab (tab) {
      return this.activeTab === tab
    }
  },

  components: {
    NavItem,
    SoundscapeList,
    SpeciesDetectionsView,
    Wikipedia
  }
}
</script>
