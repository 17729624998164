<template>
  <div class="dropdown">
    <button class="vote-button" data-bs-toggle="dropdown">
      <font-awesome-icon icon="flag" />
    </button>

    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-small arrow">
      <li><h6 class="dropdown-header">Reason for flag:</h6></li>
      <li><a href="#" class="dropdown-item" @click.prevent="flag('human_vocals')">Contains human vocals</a></li>
      <li><a href="#" class="dropdown-item" @click.prevent="flag('other')">Other</a></li>
    </ul>
  </div>
</template>

<script>
import Tooltip from 'bootstrap/js/dist/tooltip'

import { FetchRequest } from '@rails/request.js'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    }
  },

  destroyed () {
    this.destroyTooltip()
  },

  methods: {
    async flag (reason) {
      const request = new FetchRequest('PUT', this.detection.flagUrl, {
        body: JSON.stringify({ reason: reason })
      })
      const response = await request.perform()

      if (response.ok) {
        this.$nextTick(() => this.showTooltip())
      }
    },

    showTooltip () {
      this.tooltip = new Tooltip(this.$el, {
        title: 'Thank you for your feedback.',
        placement: 'left',
        trigger: 'manual'
      })
      this.tooltip.show()
    },

    destroyTooltip () {
      if (this.tooltip) {
        this.tooltip.dispose()
        this.tooltip = null
      }
    }
  },

  watch: {
    detection () {
      this.destroyTooltip()
    }
  }
}
</script>
