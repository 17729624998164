<template>
  <MapLayer name="Species" pane="species">
    <SpeciesMarker v-for="({ station, count }, index) in randomizedStations" :key="`${station.id}-${species.id}`" :species="species" :count="count" :coords="station.coords" :zoom="zoom" :delay="delay(index)">
      <template v-slot:tooltip>
        <strong>{{ species.commonName }}</strong>
        <span class="text-muted fst-italic">({{ species.scientificName }})</span>

        <br />

        {{ formatCount(count, 'detection') }}
        <span class="text-muted">({{ periodDescription(period) }})</span>
      </template>
    </SpeciesMarker>
  </MapLayer>
</template>

<script>
import MapLayer from './MapLayer'
import SpeciesMarker from 'components/markers/SpeciesMarker'

import SpeciesStationsQuery from 'queries/SpeciesStations.graphql'

import { shuffle } from 'lodash'

import { formatCount } from 'lib/formatting'
import { periodDescription } from 'lib/period'

export default {
  props: {
    species: {
      type: Object
    },

    zoom: {
      type: Number,
      default: 3
    },

    queryVariables: {
      type: Object
    },

    period: {
      type: Object,
      required: true
    },

    visibleTypes: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      stations: {}
    }
  },

  computed: {
    randomizedStations () {
      return shuffle(this.stations)
    },

    normalizedStationTypes () {
      return this.visibleTypes.map(type => {
        return type === 'app' ? ['user', 'guest'] : type
      }).flat()
    },
  },

  methods: {
    formatCount,
    periodDescription,

    delay (index) {
      // Show all stations within 1 second
      const count = this.stations.length
      return index * Math.min(0.1, 1 / count)
    }
  },

  apollo: {
    stations: {
      query: SpeciesStationsQuery,

      variables () {
        const variables = { ...this.queryVariables }
        variables.speciesId = this.species.id
        variables.stationTypes = this.normalizedStationTypes
        return variables
      },

      update: data => data.species.stations
    }
  },

  watch: {
    species () {
      this.stations = {}
    }
  },

  components: {
    MapLayer,
    SpeciesMarker
  }
}
</script>
