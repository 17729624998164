<template>
  <div :class="htmlClass">
    <input class="form-check-input" type="checkbox" :id="id" :value="value" :disabled="disabled" :checked="isChecked" @change="toggle" />
    <label v-if="hasDefaultSlot" class="form-check-label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },

  props: {
    value: {
      default: true
    },

    checked: {
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    trueValue: {
      default: true
    },

    falseValue: {
      default: false
    }
  },

  data () {
    return {
      id: null
    }
  },

  mounted () {
    this.id = this._uid
  },

  methods: {
    toggle: function (event) {
      let isChecked = event.target.checked

      if (this.checked instanceof Array) {
        let newValue = [...this.checked]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  },

  computed: {
    isChecked () {
      if (this.checked instanceof Array) {
        return this.checked.includes(this.value)
      } else {
        return this.checked === this.trueValue
      }
    },

    hasDefaultSlot () {
      return !!this.$slots.default
    },

    htmlClass () {
      return this.hasDefaultSlot ? 'form-check' : ''
    }
  }
}
</script>
