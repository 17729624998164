<template>
  <div class="map-window" :class="{ open: open }" :style="{ zIndex: 1000 + zIndex }" @touchstart="$emit('bringToFront')" @click="$emit('bringToFront')">
    <header v-if="header" class="map-window-header">
      <slot name="header">
        <h6 class="m-0">{{ title }}</h6>
      </slot>

      <button v-if="closeButton == 'close'" type="button" class="btn btn-close px-2 ms-2" @click="toggle" />
      <button v-else-if="closeButton == 'minimize'" class="btn px-1 py-0 ms-2" @click="toggle">
        <font-awesome-icon icon="chevron-down" />
      </button>
    </header>

    <div v-if="open" class="map-window-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },

    header: {
      type: Boolean,
      default: true
    },

    closeButton: {
      type: String,
      default: 'close'
    },

    zIndex: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      open: true
    }
  },

  methods: {
    toggle () {
      if (this.open) {
        this.hide()
      } else {
        this.show()
      }
    },

    hide () {
      this.open = false
      this.$emit('hide')
    },

    show () {
      this.open = true
      this.$emit('show')
    }
  }
}
</script>
