<template>
  <div class="toast-container bottom-0 start-0 p-3">
    <div ref="toast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <small>{{ statusText }}</small>
        <button v-if="isError || isCompleted" type="button" class="btn-close ms-auto" data-bs-dismiss="toast" aria-label="Close" @click="hide"></button>
      </div>

      <div class="toast-body">
        <div v-if="isError" class="progress">
          <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <div v-else-if="isCompleted" class="progress">
          <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <template v-else>
          <div class="progress mb-2">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning overflow-visible text-black px-2" role="progressbar" :style="{ width: importingProgressWidth }" :aria-valuenow="progress.importing" aria-valuemin="0" aria-valuemax="100">Importing...</div>
          </div>

          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info overflow-visible text-black px-2" role="progressbar" :style="{ width: analyzingProgressWidth }" :aria-valuenow="progress.analyzing" aria-valuemin="0" aria-valuemax="100">Analyzing...</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from 'bootstrap/js/dist/toast'

export default {
  data: () => {
    return {
      status: null,
      progress: {
        importing: 0,
        analyzing: 0
      },
      message: null,

      visible: false
    }
  },

  channels: {
    ImportChannel: {
      received (data) {
        this.status = data.status
        this.message = data.message

        this.progress[data.status] = data.progress
      },

      disconnected () {
        this.status = null
        this.progress = null
        this.message = null
      }
    },
  },

  mounted () {
    this.$cable.subscribe({
      channel: 'ImportChannel'
    })

    this.toast = new Toast(this.$refs.toast, { autohide: false })
  },

  methods: {
    show () {
      if (!this.visible) {
        this.toast.show()
        this.visible = true
      }
    },

    hide () {
      if (this.visible) {
        this.toast.hide()
        this.visible = false
      }
    },

    progressWidth (progress) {
      if (progress) {
        return `${progress}%`
      } else {
        return '100%'
      }
    }
  },

  computed: {
    statusText () {
      if (this.status === 'importing' || this.status === 'analyzing') {
        return 'Importing soundscapes...'
      } else if (this.status === 'completed') {
        return 'Import completed!'
      } else if (this.status === 'error') {
        return `Error: ${this.message}`
      }
    },

    importingProgressWidth () {
      return this.progressWidth(this.progress.importing)
    },

    analyzingProgressWidth () {
      return this.progressWidth(this.progress.analyzing)
    },

    isError () {
      return this.status === 'error'
    },

    isCompleted () {
      return this.status === 'completed'
    }
  },

  watch: {
    status (status) {
      if (status) {
        this.show()
      } else {
        this.hide()
      }

      if (status === 'completed') {
        setTimeout(() => this.status = null, 5000)
      }
    }
  }
}
</script>
