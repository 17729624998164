<template>
  <l-tile-layer :url="url" />
</template>

<script>
import { LTileLayer } from 'vue2-leaflet'

export default {
  props: {
    layer: {
      type: String,
      default: 'precipitation_new'
    }
  },

  computed: {
    url () {
      return `https://tile.openweathermap.org/map/${this.layer}/{z}/{x}/{y}.png?appid=${process.env.OPENWEATHER_API_KEY}`
    }
  },

  components: {
    LTileLayer
  }
}
</script>
