import Vue from 'vue'

import Highcharts from 'highcharts'

import stockInit from 'highcharts/modules/stock'
import coloraxisInit from 'highcharts/modules/coloraxis'
import xrangeInit from 'highcharts/modules/xrange'
import boostInit from 'highcharts/modules/boost'
import heatmapInit from 'highcharts/modules/heatmap'

import HighchartsVue from 'highcharts-vue'

stockInit(Highcharts)
coloraxisInit(Highcharts)
xrangeInit(Highcharts)
boostInit(Highcharts)
heatmapInit(Highcharts)

Vue.use(HighchartsVue, { highcharts: Highcharts })

export default Highcharts
