<template>
  <div class="detection-score" :title="description">
    <p class="m-0">{{ detection.score.toFixed(1) }}</p>
    <div class="detection-certainty" :style="certaintyStyles"></div>
  </div>
</template>

<script>
import Tooltip from 'bootstrap/js/dist/tooltip'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    },

    tooltipPlacement: {
      type: String,
      default: 'right'
    }
  },

  mounted () {
    this.initializeTooltip()
  },

  destroyed () {
    this.tooltip.dispose()
  },

  methods: {
    initializeTooltip () {
      this.tooltip = new Tooltip(this.$el, { placement: this.tooltipPlacement, html: true })
    }
  },

  computed: {
    description () {
      return [
        `Score: ${this.detection.score.toFixed(3)}`,
        `Confidence: ${(this.detection.confidence * 100).toFixed()}%`,
        `Probability: ${(this.detection.probability * 100).toFixed()}%`
      ].join('<br />')
    },

    certaintyStyles () {
      return {
        backgroundColor: this.certaintyColor
      }
    },

    certaintyColor () {
      return {
        unlikely: '#f94144',
        uncertain: '#f9c74f',
        very_likely: '#f8961e',
        almost_certain: '#90be6d'
      }[this.detection.certainty]
    }
  },

  watch: {
    detection () {
      this.tooltip.dispose()
      this.initializeTooltip()
    }
  }
}
</script>
