<template>
  <MapWindow class="species-list-window" :zIndex="zIndex" @hide="$emit('hide')" @bringToFront="$emit('bringToFront')">
    <template v-slot:header>
      <h6 class="m-0">
        Top Species
        <small class="ms-2 text-muted fw-normal">BirdNET Sightings</small>
      </h6>
    </template>

    <div class="flex-fill p-1 ps-0" style="min-height: 0">
      <TopSpeciesList :current="selectedSpecies" :query="query" :queryVariables="queryVariables" countLabel="sightings" @select="$emit('select', $event)" @loading="$emit('loading', $event)">
        <template v-slot:empty>
          No sightings within the current region for the selected time period.
        </template>
      </TopSpeciesList>
    </div>
  </MapWindow>
</template>

<script>
import MapWindow from 'components/MapWindow'
import TopSpeciesList from 'components/TopSpeciesList'

import TopBirdNETSpeciesQuery from 'queries/TopBirdNETSpecies.graphql'

export default {
  props: {
    selectedSpecies: {
      type: Object,
      required: false
    },

    queryVariables: {
      type: Object
    },

    zIndex: {
      type: Number,
      default: 1
    }
  },

  created () {
    this.query = TopBirdNETSpeciesQuery
  },

  components: {
    MapWindow,
    TopSpeciesList
  }
}
</script>
