<template>
  <LeafletHeatmap :latLng="latLngs" :radius="radius" :blur="blur" :gradient="gradient" :minOpacity="minOpacity" />
</template>

<script>
import LeafletHeatmap from './LeafletHeatmap'

export default {
  props: {
    coords: {
      type: Array,
      default: () => []
    },

    zoom: {
      type: Number,
      default: 3
    },

    minOpacity: {
      type: Number,
      default: 0.25
    },

    gradient: {
      type: Object,
      default: () => ({
        0.4: 'blue',
        0.6: 'cyan',
        0.7: 'lime',
        0.8: 'yellow',
        1.0: 'red'
      })
    }
  },

  computed: {
    latLngs () {
      return this.coords.map((c) => [c.lat, c.lon, this.minOpacity])
    },

    radius () {
      return Math.max(this.zoom - 3, 1) * 10
    },

    blur () {
      return Math.max(this.zoom - 3, 1) * 5
    }
  },

  components: {
    LeafletHeatmap,
  }
}
</script>
