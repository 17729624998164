<template>
  <div class="map-window-pane" :style="{ zIndex: zIndex ? (1000 + zIndex) : undefined }" @touchstart="$emit('bringToFront')" @click="$emit('bringToFront')">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    zIndex: {
      type: Number
    }
  }
}
</script>
