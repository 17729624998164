<template>
  <multiselect class="search-bar" @input="select($event)" :options="optionGroups" group-label="name" group-values="options" :loading="$apollo.loading" label="name" placeholder="Search by species or station name..." track-by="id" :show-labels="false" :internal-search="false" @search-change="updateQuery">
    <template v-slot:noResult>No results found.</template>
    <template v-slot:noOptions>Enter a search query above.</template>

    <template v-slot:singleLabel="{ option }">
      {{ option.name }}
      <small class="text-muted ms-1 fst-italic">{{ stationType(option.type) }}</small>
    </template>

    <template v-slot:option="{ option }">
      <span v-if="option.$isLabel">
        {{ option.$groupLabel }}
      </span>

      <template v-else-if="option.__typename === 'Species'">
        <span class="multiselect__option-image">
          <SpeciesThumbnail :species="option" class="x-small" />
        </span>

        {{ translatedCommonName(option, locale) }}
        <small class="text-muted ms-1 fst-italic">{{ option.scientificName }}</small>
      </template>

      <template v-else-if="option.__typename === 'Station'">
        <span class="multiselect__option-image">
          <StationKey :type="option.type" class="mx-auto" />
        </span>

        {{ option.name }}
        <small class="text-muted ms-1 fst-italic">{{ stationType(option.type) }}</small>
      </template>
    </template>
  </multiselect>
</template>

<script>
import SpeciesThumbnail from './SpeciesThumbnail'
import StationKey from './StationKey'

import SearchSpeciesQuery from 'queries/SearchSpecies.graphql'
import SearchStationsQuery from 'queries/SearchStations.graphql'

import { stationType } from 'lib/station'
import { translatedCommonName } from 'lib/species'

import { mapState } from 'vuex'

export default {
  data () {
    return {
      query: ''
    }
  },

  methods: {
    stationType,
    translatedCommonName,

    updateQuery (query) {
      this.query = query
    },

    select (option) {
      if (option.__typename === 'Station') {
        this.$emit('selectStation', option)
      } else if (option.__typename === 'Species') {
        this.$emit('selectSpecies', option)
      }
    }
  },

  computed: {
    ...mapState(['locale']),

    optionGroups () {
      const groups = []

      if (this.stationOptions.length) {
        groups.push({
          name: 'Stations',
          options: this.stationOptions
        })
      }

      if (this.speciesOptions.length) {
        groups.push({
          name: 'Species',
          options: this.speciesOptions
        })
      }

      return groups
    },

    stationOptions () {
      if (this.query === '' || !this.stations) {
        return []
      } else {
        return this.stations.nodes
      }
    },

    speciesOptions () {
      if (this.query === '' || !this.species) {
        return []
      } else {
        return this.species.nodes.map((species) => {
          return {
            name: `${translatedCommonName(species, this.locale)} (${species.scientificName})`,
            ...species
          }
        })
      }
    }
  },

  apollo: {
    stations: {
      query: SearchStationsQuery,

      debounce: 200,

      variables () {
        return { query: this.query }
      },

      skip () {
        return this.query === ''
      }
    },

    species: {
      query: SearchSpeciesQuery,

      debounce: 200,

      variables () {
        return {
          query: this.query,
          searchLocale: this.locale
        }
      },

      skip () {
        return this.query === ''
      }
    }
  },

  components: {
    SpeciesThumbnail,
    StationKey
  }
}
</script>
