<template>
  <div class="dropdown">
    <button ref="trigger" class="btn btn-secondary" :class="`btn-${size}`" data-bs-toggle="dropdown" data-bs-auto-close="outside">
      <font-awesome-icon icon="gear" />
    </button>

    <div class="dropdown-menu dropdown-menu-end arrow">
      <div class="py-2 px-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'sm'
    }
  }
}
</script>
